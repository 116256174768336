@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    font-family: manrope;
    src:url('med/fonts/medium.otf');
}
@font-face{
    font-family: manrope-bold;
    src:url('med/fonts/bold.otf');
}
.ripple-circle{
    --aW : 500px;
    content: '';
    position: absolute;
    width: var(--aW);
    height: var(--aW);
    background: blueviolet;
    border-radius: 50%;
    animation: ripple 0.7s;
    opacity: 0;
    transition: 0.2s;
}
@keyframes ripple {
    0%{
        transform: scale(0);
        opacity: 0.25;
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
}
.sidepanel-button{
    max-height: 0px;
    overflow: hidden;
    transition: 0.2s;
}
.sidepanel-button-open{
    max-height: 500px;
    overflow: auto;
}
.header-link::after{
    content: '';
    position: absolute;
    width: 5px;
    height: 15px;
    left: 0px;
    background: blueviolet;
    border-radius: 5px;
    opacity: 0;
    transition: 0.2s;
    visibility: hidden;
    transform: translateX(10px);
    top: calc(50% - 7.5px);
}
.header-link.active-link::after{
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
}